import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import InputColor from 'react-input-color'

import { NButton, NCarousel, NInput, NSelector } from '~/components'
import { useApi, useApiEffect } from '~/hooks'
import { getRegister, setRegister } from '~/repositories/register'
import { verifyAppData } from '~/services/apps'
import { getCitiesByState, getStates } from '~/services/locations'
import formErrors from '~/utils/formErrors'

import { Wrapper, ToolBar, Container } from './styles'
import { fields, schema } from './props'

import { ReactComponent as Print1 } from './prints/Print-1.svg'
import { ReactComponent as Print2 } from './prints/Print-2.svg'
import { ReactComponent as Print3 } from './prints/Print-3.svg'
import { ReactComponent as Print4 } from './prints/Print-4.svg'
import { ReactComponent as Print5 } from './prints/Print-5.svg'
import { ReactComponent as Print6 } from './prints/Print-6.svg'
import { ReactComponent as Print7 } from './prints/Print-7.svg'
import { ReactComponent as Print8 } from './prints/Print-8.svg'
import { ReactComponent as Print9 } from './prints/Print-9.svg'

import appLogo from '../../../../assets/logo.png'

const printsList = [
  Print1,
  Print2,
  Print3,
  Print4,
  Print5,
  Print6,
  Print7,
  Print8,
  Print9,
]

const AppForm = () => {
  const save = getRegister()

  const navigate = useNavigate()
  const { state } = useLocation()
  const { request } = useApi()

  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [colory, setColory] = useState('')

  const {
    register,
    handleSubmit,
    formState,
    watch,
    control,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: save?.app,
    mode: 'all',
  })

  const name = watch('name')
  const color = watch('color')
  const UF = watch('state')

  useApiEffect(getStates, (response) =>
    setStates(
      response.data.map((item) => ({ name: item.sigla, value: item.sigla }))
    )
  )

  useEffect(() => {
    request(
      () => getCitiesByState(UF),
      (response) =>
        setCities(
          response.data.map((item) => ({ name: item.nome, value: item.nome }))
        )
    )
  }, [UF])

  useEffect(() => {
    if (name) {
      setValue('domain', name.toLowerCase().replace(/[^a-z0-9]/g, ''))
    }
  }, [name])

  const onSubmit = (data, e) => {
    e.preventDefault()
    const { logo } = data

    delete data.logo

    setRegister({ ...(save || {}), app: data })

    data.tax = 0.2

    data.location = {
      state: data.state,
      city: data.city,
    }

    data.colors = {
      primary: colory.hex.toUpperCase(),
    }

    delete data.state
    delete data.city
    delete data.color

    request(
      () => verifyAppData(data),
      () =>
        navigate('/app/cadastro', {
          state: { ...state, app: { ...data, logo } },
        }),
      formErrors(setError)
    )
  }

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <ToolBar>
        <img
          className="logo"
          src={appLogo}
          alt="logo"
          style={{ height: '74px' }}
        />
      </ToolBar>
      <Container primary={color}>
        <div className="left">
          {Object.entries(fields).map(([key, value]) => (
            <div className="input-wrapper" key={key}>
              <h1 style={{ font: '300 30px Comfortaa,sans-serif' }}>
                Crie seu app em 2 minutos de graça!
              </h1>

              <p style={{ font: '300 16px Comfortaa,sans-serif' }}>
                Preencha o formlário abaixo para criar.
              </p>

              <p>{value.label}</p>
              <NInput
                error={formState.errors[key]?.message}
                {...value}
                {...register(key)}
              />
            </div>
          ))}
          <div className="input-color">
            <p>Mudar cor:</p>
            <InputColor
              initialValue="#944bbb"
              placement="right"
              onChange={setColory}
            />
          </div>
          <Controller
            control={control}
            name="state"
            render={({ field, fieldState }) => (
              <div className="input-wrapper">
                <p>Estado:</p>
                <NSelector
                  options={states}
                  {...field}
                  value={field.value}
                  onChange={(option) => {
                    setValue('city', '')

                    field.onChange(option.name)
                  }}
                  error={fieldState.error?.message}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name="city"
            render={({ field, fieldState }) => (
              <div className="input-wrapper">
                <p>Cidade:</p>
                <NSelector
                  options={cities}
                  {...field}
                  value={field.value}
                  onChange={(option) => field.onChange(option.name)}
                  error={fieldState.error?.message}
                />
              </div>
            )}
          />
          <NButton
            style={{ margin: '24px 0' }}
            label={'Criar meu app de graça'}
            color="green"
            /* onClick={(el) => { el.preventDefault(); setModal(true)}} */ type="submit"
          />

          <a
            href="https://www.weptek.com.br/"
            target="_blank"
            style={{
              font: '300 16px Comfortaa,sans-serif',
              textDecoration: 'underline',
            }}
            rel="noreferrer"
          >
            Saber mais informações
          </a>
        </div>

        <div className="separator" />
        <div className="right">
          <p
            style={{ font: '300 18px Comfortaa,sans-serif', marginBottom: 20 }}
          >
            Veja como vai ficar incrível o seu app
          </p>
          <NCarousel
            showArrows={true}
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
          >
            {printsList.map((Print, index) => (
              <div key={index}>
                <Print style={{ fill: colory.hex, height: '500px' }} />
              </div>
            ))}
          </NCarousel>
        </div>
      </Container>
    </Wrapper>
  )
}

export default AppForm
