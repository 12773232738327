import React from 'react'

import { Container, Top } from './styles'

const NInfoCard = ({ title = 'Viagens finalizadas', icon, info = '0' }) => (
  <Container>
    <Top>
      <div className="icon">{icon}</div>
      <p className="title">{title}</p>
    </Top>
    <div className="border" />
    <p className="info">{info}</p>
  </Container>
)

export default NInfoCard
