import { useState } from 'react'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import { api } from '~/services/api'
import {
  Active,
  Container,
  Content,
  Edit,
  Field,
  Input,
  Left,
  Menu,
  Right,
  Title,
  Top,
} from './styles'
import { useAuth } from '~/hooks'
import ModalPremium from '../ModalPremium'

const NCardCategory = ({ data }) => {
  const { user } = useAuth()
  const [visibleModal, setVisibleModal] = useState(false)

  const [hasPremium, setHasPremium] = useState(true)

  const [opened, setOpened] = useState(false)
  const [editable, setEditable] = useState(false)
  const [values, setValues] = useState({
    individualCost: Number(data.cost),
    privateCost: Number(data.privateConst),
    averageCost: Number(data.averageConst),
    roofKms: Number(data.roofKms),
    fixedPrice: data.fixedPrice,
    fixedPriceRoofKm: data.fixedPriceRoofKm,
  })

  const onCancel = () => {
    setVisibleModal(false)
  }

  const onOpenModal = () => {
    setVisibleModal(true)
  }
  return (
    <Container opened={opened}>
      <ModalPremium
        visible={visibleModal}
        label="Você tem certeza?"
        onCancel={() => onCancel()}
        confirmLabel="Sim, confimar"
        cancelLabel="Não, cancelar"
        onConfirm={() => {}}
        onClose={() => onCancel()}
      />

      <Top>
        <Left>
          <img
            src={`/images/categories/${data.category.icon}.svg`}
            alt={data.category.icon}
          />
          <span>{data.category.name}</span>
        </Left>
        <Right onClick={() => setOpened(!opened)}>
          {opened ? (
            <AiOutlineMinus
              color="#944BBB"
              size={25}
              style={{ cursor: 'pointer' }}
            />
          ) : (
            <AiOutlinePlus
              color="#944BBB"
              size={25}
              style={{ cursor: 'pointer' }}
            />
          )}
        </Right>
      </Top>
      {opened && (
        <div className="content-container">
          <Content>
            <Field>
              <Title>Preço base</Title>
              <Input
                placeholder="R$ 0"
                defaultValue={values.individualCost}
                onChange={(e) =>
                  setValues({
                    individualCost: e.target.value,
                    privateCost: values.privateCost,
                    averageCost: values.averageCost,
                    roofKms: values.roofKms,
                    fixedPriceRoofKm: values.fixedPriceRoofKm,
                    fixedPrice: values.fixedPrice,
                  })
                }
                style={
                  editable
                    ? { backgroundColor: '#4D4D4D' }
                    : { backgroundColor: '#717171' }
                }
                onKeyPress={(event) => {
                  if (
                    /[0-9]/.test(event.key) ||
                    (event.key === '.' && !values.individualCost.includes('.'))
                  ) {
                    return
                  }

                  event.preventDefault()
                }}
                disabled={editable ? false : true}
              />
            </Field>

            <Field>
              <Title>Preço do km individual</Title>
              <Input
                placeholder="R$ 0"
                defaultValue={values.privateCost}
                onChange={(e) =>
                  setValues({
                    individualCost: values.individualCost,
                    privateCost: e.target.value,
                    averageCost: values.averageCost,
                    roofKms: values.roofKms,
                    fixedPriceRoofKm: values.fixedPriceRoofKm,
                    fixedPrice: values.fixedPrice,
                  })
                }
                style={
                  editable
                    ? { backgroundColor: '#4D4D4D' }
                    : { backgroundColor: '#717171' }
                }
                onKeyPress={(event) => {
                  if (
                    /[0-9]/.test(event.key) ||
                    (event.key === '.' && !values.privateCost.includes('.'))
                  ) {
                    return
                  }

                  event.preventDefault()
                }}
                disabled={editable ? false : true}
              />
            </Field>

            <Field>
              <Title>Preço do KM excedente</Title>
              <Input
                placeholder="R$ 0"
                defaultValue={values.averageCost}
                onChange={(e) =>
                  setValues({
                    individualCost: values.individualCost,
                    privateCost: values.privateCost,
                    averageCost: e.target.value,
                    roofKms: values.roofKms,
                    fixedPriceRoofKm: values.fixedPriceRoofKm,
                    fixedPrice: values.fixedPrice,
                  })
                }
                style={
                  editable
                    ? { backgroundColor: '#4D4D4D' }
                    : { backgroundColor: '#717171' }
                }
                onKeyPress={(event) => {
                  if (
                    /[0-9]/.test(event.key) ||
                    (event.key === '.' && !values.averageCost.includes('.'))
                  ) {
                    return
                  }

                  event.preventDefault()
                }}
                disabled={editable ? false : true}
              />
            </Field>

            <Field>
              <Title>Limite de km sem excedente</Title>
              <Input
                placeholder="0"
                defaultValue={values.roofKms}
                onChange={(e) =>
                  setValues({
                    individualCost: values.individualCost,
                    privateCost: values.privateCost,
                    averageCost: values.averageCost,
                    roofKms: e.target.value,
                    fixedPriceRoofKm: values.fixedPriceRoofKm,
                    fixedPrice: values.fixedPrice,
                  })
                }
                style={
                  editable
                    ? { backgroundColor: '#4D4D4D' }
                    : { backgroundColor: '#717171' }
                }
                onKeyPress={(event) => {
                  if (
                    /[0-9]/.test(event.key) ||
                    (event.key === '.' && !values.roofKms.includes('.'))
                  ) {
                    return
                  }

                  event.preventDefault()
                }}
                disabled={editable ? false : true}
              />
            </Field>
          </Content>

          <Menu>
            <Edit
              style={
                editable
                  ? {
                      backgroundColor: '#22f100',
                      color: 'rgba(255, 255, 255, 1)',
                    }
                  : { backgroundColor: '#d2d2d2', color: 'rgba(77, 77, 77, 1)' }
              }
              onClick={
                // user?.app?.accountPremium
                hasPremium
                  ? async () => {
                      setEditable(!editable)

                      if (editable) {
                        const res = await api.put(
                          'licensed-categories/' + data.id,
                          {
                            cost: Number(values.individualCost),
                            privateConst: Number(values.privateCost),
                            averageConst: Number(values.averageCost),
                            roofKms: Number(values.roofKms),
                            fixedPrice: values.fixedPrice,
                            fixedPriceRoofKm: values.fixedPriceRoofKm,
                          }
                        )

                        if (res.status === 200) {
                          setOpened(false)
                        }
                      }
                    }
                  : () => onOpenModal()
              }
            >
              {editable ? 'Salvar' : 'Editar'}
            </Edit>
            <Active
              onClick={
                // user?.app?.accountPremium
                hasPremium
                  ? async () => {
                      const res = await api.put(
                        'licensed-categories/' + data.id,
                        {
                          active: !data.active,
                        }
                      )

                      data.active = !data.active

                      if (res.status === 200) {
                        setOpened(false)
                      }
                    }
                  : () => onOpenModal()
              }
              style={
                data.active
                  ? {
                      backgroundColor: '#d2d2d2',
                      color: 'rgba(77, 77, 77, 1)',
                    }
                  : {
                      backgroundColor: '#22f100',
                      color: 'rgba(255, 255, 255, 1)',
                    }
              }
            >
              {data.active ? 'Desativar' : 'Ativar'}
            </Active>
          </Menu>
          <div className="fixed-price">
            <input
              type="checkbox"
              defaultChecked={values.fixedPrice}
              disabled={editable ? false : true}
              onChange={(e) =>
                setValues({
                  individualCost: values.individualCost,
                  privateCost: values.privateCost,
                  averageCost: values.averageCost,
                  roofKms: values.roofKms,
                  fixedPriceRoofKm: values.fixedPriceRoofKm,
                  fixedPrice: e.target.checked,
                })
              }
            />
            <span>Preço fixo KM's individual</span>
          </div>
          <div className="fixed-price">
            <input
              type="checkbox"
              defaultChecked={values.fixedPriceRoofKm}
              disabled={editable ? false : true}
              onChange={(e) =>
                setValues({
                  individualCost: values.individualCost,
                  privateCost: values.privateCost,
                  averageCost: values.averageCost,
                  roofKms: values.roofKms,
                  fixedPriceRoofKm: e.target.checked,
                  fixedPrice: values.fixedPrice,
                })
              }
            />
            <span>Preço fixo KM's excedente</span>
          </div>
        </div>
      )}
    </Container>
  )
}

export default NCardCategory
