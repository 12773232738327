import React, { forwardRef, useState } from 'react'
import {
  Container,
  ContainerTax,
  ContainerButtonsSmall,
} from '../../pages/Charges/styles'
import NButton from '../NButton'
import { ContainerRowSaibaMais } from './styles'

const RectangleSaibaMais = ({
  title = 'Titulo',
  textButton = 'Text',
  onClickAction = () => {},
}) => {
  return (
    <Container>
      <ContainerTax>
        <ContainerRowSaibaMais>
          <p className="textNormal">{title}</p>

          <NButton
            style={{ height: 46 }}
            type="button"
            color="green"
            label={textButton}
            onClick={onClickAction}
            className="print-hide"
          />
        </ContainerRowSaibaMais>
      </ContainerTax>
    </Container>
  )
}

export default RectangleSaibaMais
