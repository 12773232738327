import styled from 'styled-components'

export const Container = styled.div`
  border-radius: 24px;
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  min-width: 420px;
  max-width: 420px;
  width: 420px;

  padding: 24px;
  height: 300px;
`

export const WrapperHeader = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const WrapperIdDotColor = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`

export const TextHeader = styled.p`
  font-size: 12px;
  color: #4d4d4d;
`
