import React, { useEffect, useRef, useState } from 'react'

import { useApi, useApiEffect } from '~/hooks'
import {
  getAppDashboardData,
  getAppData,
  getAppFinancialData,
  setAppData,
} from '~/services/apps'

import { copyText } from '~/utils/copyText'

import { AiFillCar } from 'react-icons/ai'
import { BiDollar } from 'react-icons/bi'
import { FaAddressCard } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { BsPencil } from 'react-icons/bs'
import { MdContentCopy } from 'react-icons/md'

import {
  Container,
  ContainerCards,
  ContainerTax,
  ContainerLink,
  ContainerComments,
} from './styles'
import { PageBase, NInfoCard, NTableCard, NCard } from '~/components'
import RectangleSaibaMais from '~/components/RectangleSaibaMais'
import CardPremium from '~/components/CardPremium'
import CardComments from '~/components/CardComments'
import { getAllTrip } from '~/services/trips'
import { useAuth } from '~/hooks'
import ModalPremium from '~/components/ModalPremium'

const Principal = () => {
  const { user } = useAuth()
  const [visibleModal, setVisibleModal] = useState(false)

  const [app, setApp] = useState()
  const [financial, setFinancial] = useState()
  const [domain, setDomain] = useState('')
  const [tax, setTax] = useState()
  const [disabled, setDisabled] = useState(true)
  const [hasPremium, setHasPremium] = useState(true)

  const inputRef = useRef(null)

  const [pages, setPages] = useState([])

  const [dataTrips, setDataTrips] = useState(null)

  const { request } = useApi()

  const onCancel = () => {
    setVisibleModal(false)
  }

  const getTrips = () => {
    request(
      () => getAllTrip(),
      (response) => {
        const dataResponse = response.data.content

        const valueFormattedTitle = dataResponse
          .filter((data) => data.status === 'finished')
          .map((data) => ({
            id: data?.id,
            time: data?.time,
            status: data?.status,
            allPassengers: data?.passengers.map((passenger) => ({
              id: passenger?.passengerId,
              name: passenger?.passengerName,
              rating: passenger?.rating,
              passengerComment: passenger?.passengerComent,
              photo: passenger?.photoImage,
              driverData: {
                id: data?.driver.id,
                name: data?.driver.name,
                photo: data?.driver.photo,
                driverComment: passenger?.driverComment,
              },
            })),
          }))

        setDataTrips(valueFormattedTitle)
      }
    )
  }

  useEffect(() => {
    getTrips()
  }, [])

  const attData = (data) => {
    setFinancial(data)

    setPages(data.billings.pages)
  }

  const changePage = (pageNumber) => {
    request(
      () => getAppFinancialData(pageNumber),
      (response) => attData(response.data)
    )
  }

  useApiEffect(getAppDashboardData, (response) => {
    setApp(response.data)
  })

  useApiEffect(getAppData, (response) => {
    setDomain(response.data.domain)
  })

  useApiEffect(
    () => getAppFinancialData(1),
    (response) => {
      attData(response.data)
    }
  )

  const attFinancialData = () => {
    request(getAppFinancialData, (response) => setFinancial(response.data))
  }

  const handleToggle = () => {
    // user?.app?.accountPremium ? setDisabled(!disabled) : setVisibleModal(true)
    hasPremium ? setDisabled(!disabled) : setVisibleModal(true)
  }

  useEffect(() => {
    if (!disabled && inputRef.current) {
      inputRef.current.focus()
    }
  }, [disabled])

  const changeTax = () => {
    if (tax <= 40)
      request(
        () => setAppData({ tax: Number(tax) / 100 }),
        () => attFinancialData()
      )
    else {
      alert('A taxa do licensiado não pode ultrapassar os 40%')
      setTax(financial.taxes.app.split('%')[0])
    }
  }

  return (
    <PageBase
      //title="Principal"
      content={
        <Container>
          <ModalPremium
            visible={visibleModal}
            label="Você tem certeza?"
            onCancel={() => onCancel()}
            confirmLabel="Sim, confimar"
            cancelLabel="Não, cancelar"
            onConfirm={() => {}}
            onClose={() => onCancel()}
          />

          {domain && domain !== 'default' && (
            <ContainerLink>
              <a
                href={`https://weptek.app/${domain}`}
                target="_blank"
                rel="noreferrer"
              >
                weptek.app/{domain}
              </a>
              <MdContentCopy
                size={25}
                className="icon_link"
                onClick={() => copyText(`https://weptek.app/${domain}`)}
              />
            </ContainerLink>
          )}

          {/* <RectangleSaibaMais
            title="Conheça os nossos serviços disponívies para alavancar os resultados
          do seu app"
            textButton="Saiba mais"
          /> */}

          <NCard />
          {app && (
            <ContainerCards>
              <NInfoCard
                title="Faturamento total"
                icon={<BiDollar size={25} />}
                info={app.gains.total}
              />

              <NInfoCard
                title="Viagens em andamento"
                icon={<AiFillCar size={25} />}
                info={app.trips.running}
              />
              <NInfoCard
                info={app.trips.finished}
                icon={<AiFillCar size={25} />}
              />
              <NInfoCard
                info={app.drivers}
                title="Motoristas"
                icon={<FaAddressCard size={25} />}
              />
              <NInfoCard
                info={app.passengers}
                title="Passageiros"
                icon={<FiUsers size={25} />}
              />
            </ContainerCards>
          )}

          {
            // !user?.app?.accountPremium
            hasPremium ? (
              !dataTrips?.[0] ? (
                <h1>Nenhuma avaliação encontrada </h1>
              ) : (
                <ContainerComments>
                  {dataTrips?.map((data) => (
                    <CardComments
                      id={data.id}
                      date={data.time}
                      status={data.status}
                      allPassengers={data.allPassengers}
                    />
                  ))}
                </ContainerComments>
              )
            ) : (
              <CardPremium />
            )
          }

          {financial && (
            <ContainerTax>
              <div className="input">
                <p>Taxa Weptek:</p>
                <p>{financial.taxes.weptec}</p>
              </div>

              <div className="border" />
              <div className="input">
                <p>Sua taxa:</p>
                <div>
                  <input
                    defaultValue={financial.taxes.app.split('%')[0]}
                    value={tax}
                    onChange={(e) => setTax(e.target.value)}
                    onBlur={() => changeTax()}
                    disabled={disabled}
                    ref={inputRef}
                  />
                  %
                  <button onClick={handleToggle}>
                    <BsPencil size={15} className="icon" />
                  </button>
                </div>
              </div>
              <div className="border" />

              <div className="input">
                <p>Taxa Total:</p>
                <p>{financial.taxes.total}</p>
              </div>
            </ContainerTax>
          )}
          {financial && <NTableCard drivers={financial.billings.content} />}

          {pages && pages.total > 1 && (
            <div className="div_next">
              {pages.current > 1 && (
                <button
                  onClick={() => changePage(pages.current - 1)}
                  type="button"
                  className="advanced"
                >
                  {`<`}
                </button>
              )}
              {pages.current < pages.total && (
                <button
                  onClick={() => changePage(pages.current + 1)}
                  type="button"
                  className="advanced"
                >
                  &gt;
                </button>
              )}
            </div>
          )}
        </Container>
      }
    />
  )
}

export default Principal
