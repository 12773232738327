import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  .div_next {
    display: flex;
    align-items: center;
    justify-content: center;

    color: ${({ theme }) => theme.colors.primary};

    margin-bottom: 20px;

    gap: 15px;

    .advanced {
      font-size: 30px;
    }
  }
`

export const ContainerLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px 0;

  a {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.primary};
  }
  .icon_link {
    cursor: pointer;
  }
  color: ${({ theme }) => theme.colors.primary};
  @media only screen and (min-width: 800px) {
    top: 40px;
    a {
      font-size: 25px;
    }
  }
`

export const ContainerComments = styled.div`
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: row;

  div {
    margin-right: 24px;
  }

  div:last-child {
    margin-right: 0;
  }

  /* Estilize a barra de rolagem */
  &::-webkit-scrollbar {
    height: 12px; /* Ajuste o tamanho da barra de rolagem horizontal aqui */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888; /* Cor do indicador (alça) */
    border-radius: 6px; /* Borda arredondada do indicador (alça) */
  }
`

export const ContainerCards = styled.div`
  /* display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px; */

  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: row;

  div {
    margin-right: 24px;
  }

  div:last-child {
    margin-right: 0;
  }

  /* Estilize a barra de rolagem */
  &::-webkit-scrollbar {
    height: 12px; /* Ajuste o tamanho da barra de rolagem horizontal aqui */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888; /* Cor do indicador (alça) */
    border-radius: 6px; /* Borda arredondada do indicador (alça) */
  }
`

export const ContainerTax = styled.div`
  display: flex;
  width: 100%;
  background: white;
  border-radius: 10px;
  padding: 10px 0 10px 0;
  > .border {
    border-left: 1px solid #d2d2d2;
  }
  .input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 0 20px 0;
    gap: 5px;
    width: 33%;

    p,
    input {
      font-size: 15px;
    }
    input {
      border: none;
      width: 20px;
    }

    .icon {
      margin-left: 10px;
    }

    @media only screen and (max-width: 700px) {
      flex-direction: column;
      p,
      input {
        font-size: 12px;
      }
    }
  }
`
