import React, { useState } from 'react'

import { NModalSure, PageBase } from '~/components'
import { Container } from './styles'
import premiumImg from '~/assets/servico-premium.png'
import { theme } from '~/styles'
import CongratsPremium from '~/components/CongratsPremium'
import ModalPremium from '~/components/ModalPremium'
import { useAuth } from '~/hooks'

const PremiumScreen = () => {
  const { user } = useAuth()
  const [hasPremium, setHasPremium] = useState(true)

  const [visibleModal, setVisibleModal] = useState(false)

  const payNow = () => {
    alert('Pagamento agora')
  }

  const onCancel = () => {
    setVisibleModal(false)
  }

  const onOpen = () => {
    setVisibleModal(true)
  }

  return (
    <PageBase
      content={
        <Container>
          <ModalPremium
            visible={visibleModal}
            label="Você tem certeza?"
            onCancel={() => onCancel()}
            confirmLabel="Sim, confimar"
            cancelLabel="Não, cancelar"
            onConfirm={() => {}}
            onClose={() => onCancel()}
          />

          {
            // !user?.app?.accountPremium
            hasPremium ? (
              <div style={{ padding: 32 }}>
                <div>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={premiumImg}
                      width={60}
                      height={60}
                      alt="premiumImg"
                    />
                    <p
                      style={{
                        fontSize: 28,
                        fontWeight: 700,
                        marginLeft: 12,
                        marginTop: 6,
                      }}
                    >
                      Divulgue seu app no RELEP
                    </p>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      margin: '40px 0',
                    }}
                  >
                    <div
                      style={{
                        borderBottom: '1px solid #000',
                        width: 300,
                      }}
                    />
                  </div>

                  <div style={{ margin: '0 60px' }}>
                    <p
                      style={{
                        fontSize: 20,
                      }}
                    >
                     Desenvolvemos o Relep, um aplicativo exclusivo para divulgar o seu próprio app.
                     O Relep funciona como um 'Mercado Livre' dos aplicativos, um marketplace onde clientes
                     e motoristas podem encontrar e comparar diversos aplicativos de transporte e entrega.
                    </p>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      margin: '40px 0',
                    }}
                  >
                    <div
                      style={{
                        borderBottom: '1px solid #000',
                        width: 300,
                      }}
                    />
                  </div>
                </div>

                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: '#FFF',
                    padding: 30,
                    marginBottom: 32,
                  }}
                >
                  <div>
                    <p style={{ fontSize: 20, fontWeight: 400 }}>
                      * Comparação de Preços: Os usuários podem comparar diferentes aplicativos e escolher o melhor custo-benefício.
                    </p>
                    <p style={{ fontSize: 20, fontWeight: 400 }}>
                      * Avaliações de Usuários: Os usuários veem as avaliações de outros usuários para fazer escolhas mais informadas.
                    </p>
                    <p style={{ fontSize: 20, fontWeight: 400 }}>
                      * Variedade de Opções: Os usuários têm acesso a uma ampla gama de serviços de transporte e entrega.
                    </p>
                    <p style={{ fontSize: 20, fontWeight: 400 }}>
                      * Facilidade de Uso: Os usuários encontram e utilizam aplicativos de forma simples e rápida.
                    </p>
                    <p style={{ fontSize: 20, fontWeight: 400 }}>
                      -------------------------------------------
                    </p>
                    <p style={{ fontSize: 20, fontWeight: 400 }}>
                      Com todas essas vantagens, o Relep se destaca como a melhor opção para clientes e motoristas. Cadastre-se gratuitamente no nosso aplicativo e aproveite todos os benefícios. Faça seu anúncio no Relep agora mesmo e saia na frente da concorrência!
                    </p>
                  </div>
                </div>

                <div>
                  <p
                    style={{
                      fontSize: 22,
                      textAlign: 'center',
                    }}
                  >
                    Apenas:
                  </p>

                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      backgroundColor: '#FFF',
                      padding: 30,
                      margin: '16px 0',
                    }}
                  >
                    <div>
                      <p style={{ fontSize: 50, fontWeight: 400 }}>R$ 47,90</p>
                    </div>
                  </div>

                  <p
                    style={{
                      fontSize: 20,
                      color: 'red',
                      fontWeight: 400,
                      textAlign: 'center',
                    }}
                  >
                    *Pagamento único
                  </p>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: 40,
                    }}
                  >
                    <button
                      onClick={payNow}
                      style={{
                        height: 70,
                        width: 286,
                        backgroundColor: theme.colors.primary,
                        color: '#fff',
                        fontSize: 20,
                        borderRadius: 14,
                        padding: '0px 28px',
                      }}
                    >
                      Saiba mais
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <CongratsPremium />
            )
          }
        </Container>
      }
    ></PageBase>
  )
}

export default PremiumScreen
