import React, { useState } from 'react'

import { useApi, useApiEffect } from '~/hooks'
import {
  getAppDashboardData,
  getAppData,
  getAppFinancialData,
  setAppData,
} from '~/services/apps'

import { copyText } from '~/utils/copyText'

import { AiFillCar } from 'react-icons/ai'
import { BiDollar } from 'react-icons/bi'
import { FaAddressCard } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { BsPencil } from 'react-icons/bs'
import { MdContentCopy } from 'react-icons/md'

import {
  Container,
  ContainerCards,
  ContainerTax,
  ContainerLink,
  ContainerContratar,
  CenterComponent,
} from './styles'
import { PageBase, NInfoCard, NTableCard, NCard } from '~/components'
import RectangleSaibaMais from '~/components/RectangleSaibaMais'
import RectangleContratar from '~/components/RectangleContratar'

const Divulgacao = () => {
  const [app, setApp] = useState()
  const [financial, setFinancial] = useState()
  const [domain, setDomain] = useState('')
  const [tax, setTax] = useState()

  const [pages, setPages] = useState([])

  const infoDivulgaca = [
    {
      id: 1,
      title: 'Criação de logomarca',
      description:
        'Criamos sua logomarca para sua empresa se posicionar e ganhar mercado.',
      price: '40,00',
    },
    {
      id: 2,
      title: 'Criação de Artes',
      description:
        'Criamos 5 Artes para divigação do seu app nas redes sociais.',
      price: '67,00',
    },
    {
      id: 3,
      title: 'Criação de vídeo',
      description:
        'Criamos  o seu vídeo para divulgar o seu app nas redes sociais.',
      price: '85,00',
    },
    {
      id: 4,
      title: 'Gestão de tráfego',
      description:
        'Criamos suas campanhas patrocinadas no facebook e instagram(Não incluso verba de anúncios).',
      price: '299,00/mês',
    },
    {
      id: 5,
      title: 'Criação de site',
      description:
        'Criamos o seu site com o seu próprio domínio e com a sua marca.',
      price: '79,90/mês',
    },
    {
      id: 6,
      title: 'Consultoria ',
      description:
        'Contrate um especialista para te ajudar a faturar alto rodos os meses.',
      price: '299,90',
    },
  ]

  const attData = (data) => {
    setFinancial(data)

    setPages(data.billings.pages)
    console.log(pages)
  }

  const changePage = (pageNumber) => {
    request(
      () => getAppFinancialData(pageNumber),
      (response) => attData(response.data)
    )
  }

  const { request } = useApi()

  useApiEffect(getAppDashboardData, (response) => {
    setApp(response.data)
  })

  useApiEffect(getAppData, (response) => {
    setDomain(response.data.domain)
  })

  useApiEffect(
    () => getAppFinancialData(1),
    (response) => {
      attData(response.data)
    }
  )

  const attFinancialData = () => {
    request(getAppFinancialData, (response) => setFinancial(response.data))
  }

  const changeTax = () => {
    if (tax <= 40)
      request(
        () => setAppData({ tax: Number(tax) / 100 }),
        () => attFinancialData()
      )
    else {
      alert('A taxa do licensiado não pode ultrapassar os 40%')
      setTax(financial.taxes.app.split('%')[0])
    }
  }

  return (
    <PageBase
      title="Divulgação"
      content={
        <Container>
          {domain && domain !== 'default' && (
            <ContainerLink>
              <a
                href={`https://weptek.app/${domain}`}
                target="_blank"
                rel="noreferrer"
              >
                weptek.app/{domain}
              </a>
              <MdContentCopy
                size={25}
                className="icon_link"
                onClick={() => copyText(`https://weptek.app/${domain}`)}
              />
            </ContainerLink>
          )}
          <NCard />
          <RectangleSaibaMais
            title="Conheça os nossos serviços disponívies para alavancar os resultados
          do seu app"
            textButton="Saiba mais"
          />

          <ContainerContratar>
            {infoDivulgaca.map((data) => (
              <RectangleContratar
                key={data.id}
                title={data.title}
                description={data.description}
                price={data.price}
              />
            ))}
          </ContainerContratar>
        </Container>
      }
    />
  )
}

export default Divulgacao
