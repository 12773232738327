import React, { useMemo, useState } from 'react'
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  Marker,
  InfoWindowF,
} from '@react-google-maps/api'
import { PageBase, NCardPassenger, NSearch } from '~/components'
import { useApi, useApiEffect } from '~/hooks'
import { getPassengers, getPassengersNoLimit } from '~/services/passengers'
import { Container } from './styles'
import { FaWhatsapp } from 'react-icons/fa'
import { getDriversNoLimit } from '~/services/drivers'

const MapScreen = () => {
  const [markerIndex, setMarkerIndex] = useState(null)
  const [markerPosition, setMarkerPosition] = useState(null)
  const [infoWindowOpen, setInfoWindowOpen] = useState(false)

  const isDriver = true

  const [peopleOnline, setPeopleOnline] = useState([])

  const [selectedLocation, setSelectedLocation] = useState({
    lat: -2.530705,
    lng: -44.297935,
  })

  const message = 'Olá, motorista eu vim pelo site weptek.com' // Mensagem que deseja enviar

  const sendMessageWhatsapp = (phoneNumber) => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`
    window.open(url, '_blank')
  }

  useApiEffect(getDriversNoLimit, (response) => {
    const res = response.data.content
    const onlineDrivers = []

    res.map((data) => {
      if (data.online === false) {
        //FIX: CORRIGIR E FIX DEIXAR TRUE
        onlineDrivers.push(data)
      }
    })

    setPeopleOnline(onlineDrivers)
  })

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  })
  const mapRef = React.useRef()
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map
  }, [])
  if (loadError) return 'Error'
  if (!isLoaded) return 'Maps'

  const imgRed = require('../../assets/maps-circle-red.png')
  const imgGreen = require('../../assets/maps-circle-green.png')
  const imgPurple = require('../../assets/maps-circle-purple.png')

  const pins = [
    { lat: -2.530705, lng: -44.297935, label: 'A', color: 'green' },
    { lat: 28.7241, lng: 77.1001, label: 'B', color: 'red' },
    { lat: 28.7241, lng: 77.1095, label: 'B', color: 'purple' },
    // Adicione mais pins conforme necessário
  ]

  const getPinIcon = (color) => {
    return {
      url: color === 'green' ? imgGreen : color === 'red' ? imgRed : imgPurple,
      scaledSize: new window.google.maps.Size(30, 30),
      backgroundColor: 'red',
      borderRadius: '50%',
      resizeMode: 'cover',
      padding: 8,
    }
  }

  const getPinIconWithPhoto = (photo) => {
    return {
      url: photo ? photo : imgPurple,
      scaledSize: new window.google.maps.Size(42, 42),
    }
  }

  const handleMarkerClick = (event, index) => {
    console.log('eventevent', event)
    setInfoWindowOpen(true)
    setMarkerIndex(index)

    // setMarkerPosition({ lat: event.latLng.lat(), lng: event.latLng.lng() })
    // setInfoWindowOpen(true)
  }

  const handleCloseInfoWindow = () => {
    setInfoWindowOpen(false)
    setMarkerPosition(null)
    setMarkerIndex(null)
  }

  const infoWindowStyle = {
    background: '#fff',
    padding: '10px',
    borderRadius: '5px',
    maxWidth: '260px',
  }

  return (
    <PageBase
      title="Mapa"
      content={
        <Container>
          <div>
            <div style={{ height: '90vh', width: '100%' }}>
              <GoogleMap
                mapContainerStyle={{
                  height: '100%',
                }}
                center={selectedLocation}
                zoom={13}
                onLoad={onMapLoad}
              >
                {/* <MarkerF
          position={selectedLocation}
          icon={'http://maps.google.com/mapfiles/ms/icons/green-dot.png'}
        /> */}

                <div
                  style={{
                    width: 380,
                    height: 60,
                    backgroundColor: 'white',
                    zIndex: 9999,
                    position: 'absolute',
                    marginTop: 50,
                    marginRight: 60,
                    display: 'flex',
                    right: 0,
                    justifyContent: 'space-around',
                    borderRadius: 14,
                    padding: 2,
                  }}
                >
                  <div
                    style={{
                      width: 100,
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: '#944BBB',
                          height: 25,
                          width: 25,
                          borderRadius: 16,
                          marginRight: 8,
                        }}
                      />
                      <p>{peopleOnline?.length}</p>
                    </div>

                    <p style={{ textAlign: 'center', fontSize: 12 }}>
                      Motoristas online
                    </p>
                  </div>

                  <div
                    style={{
                      width: 100,
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: '#E50707',
                          height: 25,
                          width: 25,
                          borderRadius: 16,
                          marginRight: 8,
                        }}
                      />
                      <p>-</p>
                    </div>

                    <p style={{ textAlign: 'center', fontSize: 12 }}>
                      Chamados pendentes
                    </p>
                  </div>

                  <div
                    style={{
                      width: 100,
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: '#22F100',
                          height: 25,
                          width: 25,
                          borderRadius: 16,
                          marginRight: 8,
                        }}
                      />
                      <p>-</p>
                    </div>

                    <p style={{ textAlign: 'center', fontSize: 12 }}>
                      Chamados em andamento
                    </p>
                  </div>
                </div>

                {peopleOnline?.map((data, index) => (
                  <MarkerF
                    key={index}
                    position={{
                      lat: Number(data.latitude),
                      lng: Number(data.longitude),
                    }}
                    icon={getPinIconWithPhoto(data.photo)}
                    onClick={() => handleMarkerClick(data, index)}
                  >
                    <div
                      style={{
                        border: '2px solid red',
                        borderRadius: '50%',
                        backgroundColor: 'red',
                      }}
                    ></div>
                    {infoWindowOpen && markerIndex === index && (
                      <InfoWindowF
                        options={{
                          pixelOffset: new window.google.maps.Size(0, -30),
                        }}
                        position={markerPosition}
                        onCloseClick={handleCloseInfoWindow}
                      >
                        {isDriver ? (
                          <div style={infoWindowStyle}>
                            <div
                              style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                borderBottom: '1px solid #000',
                                paddingBottom: '6px',
                              }}
                            >
                              <div style={{ marginRight: '10px' }}>
                                <p>{data.name}</p>
                              </div>

                              <button
                                onClick={() => sendMessageWhatsapp(data.phone)}
                              >
                                <FaWhatsapp size={30} />
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div style={infoWindowStyle}>
                            <div
                              style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                borderBottom: '1px solid #000',
                                paddingBottom: '6px',
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: '#E50707',
                                  height: 20,
                                  width: 20,
                                  borderRadius: 16,
                                  marginRight: 8,
                                }}
                              />

                              <div style={{ marginRight: '10px' }}>
                                <p>Aguardando motorista</p>
                              </div>
                            </div>

                            <div
                              style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 6,
                                marginBottom: 12,
                              }}
                            >
                              <div>
                                <p>Fernando Assis</p>
                              </div>

                              <div style={{ marginLeft: '10px' }}>
                                <p>R$ 10,00</p>
                              </div>
                            </div>

                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              <div>
                                <div
                                  style={{
                                    backgroundColor: '#000',
                                    height: 8,
                                    width: 8,
                                    borderRadius: 16,
                                  }}
                                />
                                <div
                                  style={{
                                    backgroundColor: '#000',
                                    height: 28,
                                    width: 1,
                                    marginLeft: 3,
                                  }}
                                />
                                <div
                                  style={{
                                    backgroundColor: '#000',
                                    height: 8,
                                    width: 8,
                                    borderRadius: 16,
                                  }}
                                />
                              </div>

                              <div
                                style={{
                                  marginLeft: 6,
                                  justifyContent: 'space-between',
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <div style={{ width: 210 }}>
                                  <p
                                    style={{
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    Rua xyz aaaaa bbbb ccc dddsadsadsadsa
                                  </p>
                                </div>

                                <div style={{ width: 210 }}>
                                  <p
                                    style={{
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    Rua xyz aaaaa bbbb ccc ddd
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </InfoWindowF>
                    )}
                  </MarkerF>
                ))}

                {pins[10] &&
                  pins.map((pin, index) => (
                    <MarkerF
                      key={index}
                      position={{ lat: pin.lat, lng: pin.lng }}
                      icon={getPinIcon(pin.color)}
                      onClick={() => handleMarkerClick(pin, index)}
                    >
                      {infoWindowOpen && markerIndex === index && (
                        <InfoWindowF
                          options={{
                            pixelOffset: new window.google.maps.Size(0, -30),
                          }}
                          position={markerPosition}
                          onCloseClick={handleCloseInfoWindow}
                        >
                          <div style={infoWindowStyle}>
                            <div
                              style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                borderBottom: '1px solid #000',
                                paddingBottom: '6px',
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: '#E50707',
                                  height: 20,
                                  width: 20,
                                  borderRadius: 16,
                                  marginRight: 8,
                                }}
                              />

                              <div style={{ marginRight: '10px' }}>
                                <p>Aguardando motorista</p>
                              </div>
                            </div>

                            <div
                              style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 6,
                                marginBottom: 12,
                              }}
                            >
                              <div>
                                <p>Fernando Assis</p>
                              </div>

                              <div style={{ marginLeft: '10px' }}>
                                <p>R$ 10,00</p>
                              </div>
                            </div>

                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              <div>
                                <div
                                  style={{
                                    backgroundColor: '#000',
                                    height: 8,
                                    width: 8,
                                    borderRadius: 16,
                                  }}
                                />
                                <div
                                  style={{
                                    backgroundColor: '#000',
                                    height: 28,
                                    width: 1,
                                    marginLeft: 3,
                                  }}
                                />
                                <div
                                  style={{
                                    backgroundColor: '#000',
                                    height: 8,
                                    width: 8,
                                    borderRadius: 16,
                                  }}
                                />
                              </div>

                              <div
                                style={{
                                  marginLeft: 6,
                                  justifyContent: 'space-between',
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <div style={{ width: 210 }}>
                                  <p
                                    style={{
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    Rua xyz aaaaa bbbb ccc dddsadsadsadsa
                                  </p>
                                </div>

                                <div style={{ width: 210 }}>
                                  <p
                                    style={{
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    Rua xyz aaaaa bbbb ccc ddd
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </InfoWindowF>
                      )}
                    </MarkerF>
                  ))}
              </GoogleMap>
            </div>
          </div>
        </Container>
      }
    ></PageBase>
  )
}

export default MapScreen
