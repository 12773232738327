import React from 'react'

import {
  Container,
  ContentItem,
  Top,
  LabelItem,
  Scroll,
  ContentDriversCards,
  ContainerName,
  ContainerNotFoundDatas,
} from './styles'
import { FaAddressCard } from 'react-icons/fa'
import { BiCheck, BiDollar, BiDollarCircle, BiUser } from 'react-icons/bi'
import { BsCheck2, BsQuestion, BsQuestionLg } from 'react-icons/bs'
import { ImCancelCircle } from 'react-icons/im'
import { useApi } from '~/hooks'
import { computePayment } from '~/services/apps'
import { MdSearchOff } from 'react-icons/md'

const NTableCard = ({ drivers = [] }) => {
  const { request } = useApi()
  function payed(id, name) {
    request(
      () => computePayment(id),
      () => alert(`Registrado que o motorista ${name} pagou ao app`)
    )
  }

  const driversCards = drivers?.map((driver, index) => (
    <Scroll>
      <ContentItem key={index}>
        <ContainerName>
          <BiUser className="icon" />
          <p className="label_truncate">{driver.driver.name}</p>
        </ContainerName>

        <p className="item">{driver.gains.total}</p>

        <p className="item">{driver.gains.payment}</p>

        <p className="item">{driver.paid ? 'pago' : 'não pago'}</p>

        {/* {!driver.paid && (
          <BiCheck
            size={22}
            className="icon"
            onClick={() => payed(driver.id, driver.driver.name)}
          />
        )} */}
      </ContentItem>

      {/* <LabelItem>
        <div className="info">
          <BiUser size={22} className="icon" />
          <p>
            {driver.driver.name.slice(0, 10)} - {driver.gains.total} -{' '}
            {driver.gains.payment} - {driver.paid ? 'pago' : 'não pago'}
          </p>

          {!driver.paid && (
            <BiCheck
              size={30}
              className="icon"
              onClick={() => payed(driver.id, driver.driver.name)}
            />
          )}
        </div>

        <div className="border" />
      </LabelItem> */}
    </Scroll>
  ))

  return (
    <Container>
      <Top>
        <div className="div_name">
          <FaAddressCard className="icon" />
          <p>Motoristas</p>
        </div>

        {/* <div className="div_name">
          <BiDollar className="icon" />
          <p>Pix</p>
        </div> */}

        <div className="div_name">
          <BiDollarCircle className="icon" />
          <p>Faturamento total</p>
        </div>
        <div className="div_name">
          <ImCancelCircle className="icon" />
          <p>A receber</p>
        </div>
        <div className="div_name">
          <BsCheck2 className="icon" />
          <p>Status</p>
        </div>
      </Top>
      {drivers?.[0] ? (
        <ContentDriversCards>{driversCards}</ContentDriversCards>
      ) : (
        <ContainerNotFoundDatas>
          <p>Nenhum dado encontrado</p>
          <MdSearchOff color="#4D4D4D" size={42} />
        </ContainerNotFoundDatas>
      )}
    </Container>
  )
}

export default NTableCard
