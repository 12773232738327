import React from 'react'
import { theme } from '~/styles'

import { Container } from './styles'
import premiumImg from '~/assets/servico-premium.png'
import { Link } from 'react-router-dom'

const ModalPremium = ({
  visible,
  onConfirm = () => null,
  onClose = () => null,
  ...rest
}) => {


  return (
    <Container visible={visible} {...rest} onClose={onClose}>
      <div style={{ width: '100%' }}>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src={premiumImg} width={60} height={60} alt="premiumImg" />
          <p
            style={{
              fontSize: 28,
              fontWeight: 700,
              marginLeft: 12,
              marginTop: 6,
            }}
          >
            Seja Premium
          </p>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '40px 0',
          }}
        >
          <div
            style={{
              borderBottom: '1px solid #D9D9D9',
              width: 300,
            }}
          />
        </div>

        <p
          style={{
            fontSize: 22,
            textAlign: 'center',
            marginBottom: 22,
          }}
        >
          Faça upgrade para liberar essa função!
        </p>

        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#D9D9D9',
            padding: 30,
            margin: '16px 0',
          }}
        >
          <div>
            <p style={{ fontSize: 50, fontWeight: 400 }}>R$ 47,90</p>
          </div>
        </div>

        <p
          style={{
            fontSize: 20,
            color: 'red',
            fontWeight: 400,
            textAlign: 'center',
          }}
        >
          *Pagamento único
        </p>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 40,
          }}
        >
          <Link
            style={{
              height: 70,
              width: 286,
              backgroundColor: theme.colors.primary,
              fontSize: 20,
              borderRadius: 14,
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              textDecoration: 'none',
            }}
            to="/premium"
            className="option"
          >
            <p style={{ color: '#FFF', fontSize: 20 }}>Quero ser premium</p>
          </Link>

          {/* <button
            onClick={() => handleNavigatePremiumPage()}
            style={{
              height: 70,
              width: 286,
              backgroundColor: theme.colors.primary,
              color: '#fff',
              fontSize: 20,
              borderRadius: 14,
              padding: '0px 28px',
            }}
          >
            Quero ser premium
          </button> */}
        </div>
      </div>
    </Container>
  )
}

export default ModalPremium
