import React from 'react'

const DividerCustom = ({ color = '#000', width = 300, margin = '40px 0' }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: margin,
    }}
  >
    <div
      style={{
        borderBottom: `1px solid ${color}`,
        width: width,
      }}
    />
  </div>
)

export default DividerCustom
