import React from 'react'

import {
  Container,
  WrapperHeader,
  TextHeader,
  WrapperIdDotColor,
} from './styles'
import CardCommentsPeoples from '../CardCommentsPeoples'
import DividerCustom from '../Divider'

const CardComments = ({
  id = 'XXXXXX',
  date = '13/01/2023 - 12:20',
  status = 'canceled',
  allPassengers,
  ...rest
}) => {
  const showStatusColor = (status) => {
    switch (status) {
      case 'finished':
        return (
          <WrapperIdDotColor
            style={{
              backgroundColor: '#16C628',
              height: 25,
              width: 25,
              borderRadius: 18,
              marginRight: 6,
            }}
          />
        )

      case 'canceled':
        return (
          <WrapperIdDotColor
            style={{
              backgroundColor: '#E50707',
              height: 25,
              width: 25,
              borderRadius: 18,
              marginRight: 6,
            }}
          />
        )

      default:
        return (
          <WrapperIdDotColor
            style={{
              backgroundColor: '#a5a5a5',
              height: 25,
              width: 25,
              borderRadius: 18,
              marginRight: 6,
            }}
          />
        )
    }
  }

  return (
    <Container>
      <WrapperHeader>
        <WrapperIdDotColor>
          {showStatusColor(status)}

          <TextHeader>{id}</TextHeader>
        </WrapperIdDotColor>

        <TextHeader>{date}</TextHeader>
      </WrapperHeader>

      <DividerCustom color="#D2D2D2" width={200} margin="16px 0" />

      {/* PEOPLES COMMENTS */}

      <div
        style={{
          width: '100%',
          height: 200,
          overflow: 'auto',
        }}
      >
        {allPassengers &&
          allPassengers.map((passData) => (
            <CardCommentsPeoples data={passData} />
          ))}
      </div>
    </Container>
  )
}

export default CardComments
