import styled from 'styled-components'

export const Container = styled.div`
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;

  max-width: 300px;

  padding: 15px 55px;

  .info {
    font-size: 25px;
    padding: 25px 0 25px 0;
  }

  .border {
    width: 150px;
    border-bottom: 1px solid #d2d2d2;
  }
`

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 230px;

  > .title {
    font-size: 18.5px;
  }

  > .icon {
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 6px;
  }
`
