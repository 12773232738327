import React from 'react'

import { theme } from '~/styles'
import premiumImg from '~/assets/servico-premium.png'

const CongratsPremium = () => (
  <div
    style={{
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '90vh',
    }}
  >
    <div
      style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}
    >
      <img src={premiumImg} width={60} height={60} alt="premiumImg" />
      <p
        style={{
          fontSize: 28,
          fontWeight: 700,
          marginLeft: 12,
          marginTop: 6,
        }}
      >
        Seja Premium
      </p>
    </div>

    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '40px 0',
      }}
    >
      <div
        style={{
          borderBottom: '1px solid #000',
          width: 300,
        }}
      />
    </div>

    <div style={{ margin: '0 60px' }}>
      <p
        style={{
          fontSize: 20,
        }}
      >
        Agora sua conta é premium e você pode contar com recursos exclusivos
        para fazer seu app ser um sucesso. Instale o weptekAdm
      </p>
    </div>

    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '40px 0',
      }}
    >
      <div
        style={{
          borderBottom: '1px solid #000',
          width: 300,
        }}
      />
    </div>

    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 40,
      }}
    >
      <button
        style={{
          height: 70,
          width: 286,
          backgroundColor: theme.colors.primary,
          color: '#fff',
          fontSize: 20,
          borderRadius: 14,
          padding: '0px 28px',
        }}
      >
        Instalar agora
      </button>
    </div>
  </div>
)

export default CongratsPremium
