import { useState } from 'react'

import {
  AiFillCar,
  AiOutlineCheckCircle,
  // AiOutlineQuestionCircle,
  // AiOutlineUser,
  AiOutlineImport,
  AiOutlineInfoCircle,
  AiOutlineSetting,
  AiOutlineDownload,
  AiOutlineEnvironment,
} from 'react-icons/ai'
import { BiCategory, BiTimeFive } from 'react-icons/bi'
import { BsFillMegaphoneFill, BsPalette } from 'react-icons/bs'
import { CgArrowsExchange } from 'react-icons/cg'
import { FaAddressCard, FaBars } from 'react-icons/fa'
import { FiMapPin, FiUsers, FiDollarSign } from 'react-icons/fi'
import { ImCancelCircle } from 'react-icons/im'
// import { MdAddRoad } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

import { useApi, useApiEffect, useOutsideClick } from '~/hooks'
import { removeAuth } from '~/repositories/auth'

import { getAppData, setAppData } from '~/services/apps'
import NSidebarItem from '../NSidebarItem'
import { Container, ContainerTop, Sidbar, SidebarItem, Sombra } from './styles'

const Sidebar = () => {
  const navigate = useNavigate()
  const [appName, setAppName] = useState('Weptek')
  const [app, setApp] = useState()

  const { request } = useApi()

  useApiEffect(
    getAppData,
    (response) => {
      setAppName(response.data.name)
      setApp(response.data)
    },
    () => alert('error')
  )

  function changeAppName() {
    if (appName.length < 4) {
      alert('Nome muito curto')
      setAppName(app.name)
      return
    }
    if (appName !== app.name)
      request(
        () => setAppData({ name: appName }),
        () => {
          alert('Nome alterado com sucesso')
          setApp({ name: appName })
        },
        () => alert('error')
      )
  }

  const input = useOutsideClick(() => changeAppName())

  const [close, setClose] = useState(false)
  const [viagens, setViagens] = useState(false)
  const [config, setConfig] = useState(false)
  const sidebar = useOutsideClick(() => setClose(false))
  const viagensClose = useOutsideClick(() => setViagens(false))
  const configClose = useOutsideClick(() => setConfig(false))

  return (
    <Container>
      <ContainerTop>
        <FaBars size={24} onClick={() => setClose(true)} className="icon_sid" />

        <p className="title">{appName}</p>
      </ContainerTop>
      <Sombra close={close} />
      <Sidbar ref={sidebar} close={close}>
        <div className="title_div">
          <p className="titleDash">{appName}</p>
        </div>

        <NSidebarItem
          label="Principal"
          icon={<AiOutlineInfoCircle className="icon" />}
          route="/"
        />

        <NSidebarItem
          label="Mapa"
          icon={<AiOutlineEnvironment className="icon" />}
          route="/mapa"
        />
        {/*
        <NSidebarItem
          label="Perfil"
          icon={<AiOutlineUser className="icon" />}
          route="/perfil"
        /> */}
        <NSidebarItem
          label="Personalização"
          icon={<BsPalette className="icon" />}
          route="/personalizacao"
        />
        <NSidebarItem
          label="Viagens"
          arrow
          arrowBottom={viagens}
          icon={<AiFillCar className="icon" />}
          onClick={() => setViagens(!viagens)}
        />
        <SidebarItem ref={viagensClose} close={viagens} items={3}>
          <NSidebarItem
            label="Em andamento"
            icon={<BiTimeFive className="icon" />}
            route="/viagens/em-andamento"
          />
          <NSidebarItem
            label="Finalizadas"
            icon={<AiOutlineCheckCircle className="icon" />}
            route="/viagens/finalizadas"
          />
          <NSidebarItem
            label="Canceladas"
            icon={<ImCancelCircle className="icon" />}
            route="/viagens/canceladas"
          />
        </SidebarItem>

        <NSidebarItem
          label="Passageiros"
          icon={<FiUsers className="icon" />}
          route="/passageiros"
        />

        <NSidebarItem
          label="Motoristas"
          icon={<FaAddressCard className="icon" />}
          route="/motoristas"
        />

        {/* <NSidebarItem
          label="Divulgação"
          icon={<BsFillMegaphoneFill className="icon" />}
          route="/divulgacao"
        /> */}

        <NSidebarItem
          label="Configurações"
          arrow
          arrowBottom={config}
          icon={<AiOutlineSetting className="icon" />}
          onClick={() => setConfig(!config)}
        />

        <SidebarItem ref={configClose} close={config} items={2}>
          {/*<NSidebarItem
            label="Áreas"
            icon={<FiMapPin className="icon" />}
            route="/bairros"
          />
          <NSidebarItem
            label="Rotas"
            icon={<CgArrowsExchange className="icon" />}
            route="/rotas"
          /> */}
          <NSidebarItem
            label="Categorias"
            icon={<BiCategory className="icon" />}
            route="/categorias"
          />

          <NSidebarItem
            label="Cobranças"
            icon={<FiDollarSign className="icon" />}
            route="/cobrancas"
          />

          {/* <NSidebarItem
            label="Endereços"
            icon={<MdAddRoad className="icon" />}
            route="/perfil"
          /> */}
        </SidebarItem>
        {/*
        <NSidebarItem
          label="Suporte"
          icon={<AiOutlineQuestionCircle className="icon" />}
          route="/"
        /> */}

        <NSidebarItem
          label="Driver app"
          // arrow
          // arrowBottom={config}
          icon={<AiOutlineDownload className="icon" />}
          onClick={() =>
            window.open('https://www.weptek.com.br/driverapp', '_blank')
          }
        />

        <NSidebarItem
          label="Dash APP"
          // arrow
          // arrowBottom={config}
          icon={<AiOutlineDownload className="icon" />}
          onClick={() =>
            window.open('https://drive.google.com/file/d/1pato46pezT_9IeGkCZhmkKNRnGlF9r10/view?usp=sharing', '_blank')
          }
        />    

        <NSidebarItem
          label="Sair"
          icon={<AiOutlineImport className="icon" />}
          onClick={() => {
            removeAuth()

            navigate('/')

            window.location.reload()
          }}
        />
      </Sidbar>
    </Container>
  )
}

export default Sidebar
