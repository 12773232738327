import styled from 'styled-components'

export const ContentDriversCards = styled.div`
  overflow-y: auto;
  height: 300px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;
  /* max-height: 40vh; */

  border-radius: 20px;

  padding: 20px 20px 0px 20px;

  .icon {
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    margin-right: 6px;
  }
`

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding-bottom: 20px;
  border-bottom: 1px solid #d2d2d2;

  .div_name {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    /* gap: 10px; */

    p {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 80%;
    }
  }

  @media only screen and (max-width: 700px) {
    padding-bottom: 20px;
    border-bottom: 1px solid #d2d2d2;

    .icon {
      display: none;
    }

    .div_name {
      p {
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80%;
      }
    }
  }
  /*
  @media only screen and (min-width: 1000px) {
    padding-bottom: 20px;
    border-bottom: 1px solid #d2d2d2;
    height: 100%;
    p {
      font-size: 18px;
    }
  } */
`

export const ContainerName = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: start;
  min-width: 80px;
  width: 25%;
  display: flex;

  .icon {
    height: 22px;
    width: 22px;
  }

  .label_truncate {
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
  }

  @media only screen and (max-width: 700px) {
    .icon {
      height: 16px;
      width: 16px;
    }
    .label_truncate {
      font-size: 12px;
    }
  }
`

export const ContentItem = styled.div`
  display: flex;
  justify-content: space-between;
  /* overflow: hidden; */
  height: 0px;
  padding: 16px 0;
  align-items: center;

  .item {
    display: flex;
    justify-content: start;
    align-items: center;
    min-width: 80px;
    width: 25%;
    /* gap: 10px; */
    font-size: 16px;

    .label {
      font-size: 15px;
    }

    .truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 80%;
    }

    .name {
      max-width: 230px;
    }
  }

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    gap: 10px;
    font-size: 15px;

    .label {
      font-size: 15px;
    }

    .truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 80%;
    }

    .name {
      max-width: 230px;
    }
  }

  @media only screen and (max-width: 700px) {
    /* padding: 20px 0 20px 0; */
    /* height: 100%; */

    .item_name {
      font-size: 10px;

      .icon {
        height: 16px;
        width: 16px;
      }

      .label {
        font-size: 10px;
      }
    }

    .item {
      font-size: 12px;

      .label {
        font-size: 10px;
      }
    }
  }
  /*
  @media only screen and (min-width: 1000px) {
    .item {
      font-size: 20px;
      .label {
        font-size: 20px;
      }
    }
  } */
`

export const LabelItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 10px 0 10px 0;
  width: 100%;
  overflow: hidden;

  .border {
    height: 1px;
    width: 100px;
    background: #d2d2d2;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    gap: 10px;
    p {
      font-size: 14px;
    }
  }

  @media only screen and (min-width: 700px) {
    height: 0px;
    padding: 0px;
  }
`

export const Scroll = styled.div`
  overflow-y: none;
  padding: 8px 0;
`

export const ContainerNotFoundDatas = styled.div`
  height: 160px;
  align-items: center;
  justify-content: center;
  display: flex;

  p {
    font-size: 24px;
    margin-right: 8px;
  }
`
